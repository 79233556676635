import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ImageWithBasePath from '../../../core/img/ImageWithBasePath';
import { all_routes } from '../../../core/data/routes/all_routes';
import axios from 'axios';
import { Modal } from 'bootstrap';
import * as Icon from 'react-feather';
import styles from '../../../style/admin/css/admin.module.css'

const AdminSignin = () => {
  const [isToggle, setIsToggle] = useState(false);
  const routes = all_routes;
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setEmailError('');
    setPasswordError('');

    const loadingModalElement = document.getElementById('loadingModal');
    const loadingModal = new Modal(loadingModalElement);
    loadingModal.show();

    try {
      const response = await axios.post(
        'https://wgjgzlvwmoavtpeylund.supabase.co/auth/v1/token?grant_type=password',
        {
          email,
          password,
        },
        {
          headers: {
            apiKey: process.env.REACT_APP_BEARER_TOKEN,
          },
        },
      );

      loadingModal.hide();

      sessionStorage.setItem('access_token', response.data.access_token);
      sessionStorage.setItem('user_id', response.data.user.id);
      sessionStorage.setItem('expires_at', response.data.expires_at);

      // const config = {
      //   headers: {
      //     Authorization: `Bearer ${response.data.access_token}`,
      //     apiKey: process.env.REACT_APP_BEARER_TOKEN,
      //   },
      // };

      // const secondResponse = await axios.get(
      //   `https://wgjgzlvwmoavtpeylund.supabase.co/rest/v1/user_roles?user_id=eq.${response.data.user.id}&select=role`,
      //   config,
      // );

      navigate(routes.dashboard);
      sessionStorage.setItem('isLoggedIn', 'true');
    } catch (error) {
      loadingModal.hide();

      console.error('Error:', error);

      // If the login fails, display an error message
      if (error.response && error.response.status === 400) {
        setPasswordError('Invalid email or password');
      }
    } finally {
      loadingModal.hide(); // Hide the loading modal regardless of success or failure
    }
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <>
      <div className="login-pages">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div to={routes.homeOne} className="login-logo">
                <ImageWithBasePath
                  src="assets/img/logovnbig.jpeg"
                  className="img-fluid logo"
                  alt="Logo"
                />
              </div>
            </div>
            <div className="content">
              <div className="container">
                <div className="row">
                  <div className="col-md-6 col-lg-6 mx-auto">
                    <div className="login-wrap">
                      <div className="login-headerr">
                        <h3>Admin Login</h3>
                        <p>Please enter your details</p>
                      </div>
                      {/* Login Form */}
                      <form onSubmit={handleSubmit}>
                        <div className="log-form">
                          <div className="form-group">
                            <label className="col-form-label">Email</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="johndoe@example.com"
                              value={email}
                              onChange={handleEmailChange}
                              required
                            />
                            {emailError && (
                              <div className="error">{emailError}</div>
                            )}
                          </div>
                          <div className="form-group">
                            <div className="row">
                              <div className="col">
                                <label className="col-form-label">
                                  Password
                                </label>
                              </div>
                            </div>
                            <div className="pass-group">
                              <input
                                type={passwordVisible ? 'text' : 'password'}
                                className="form-control pass-input"
                                placeholder="*************"
                                value={password}
                                onChange={handlePasswordChange}
                                required
                              />
                              <span
                                className="toggle-password"
                                onClick={togglePasswordVisibility}
                              >
                                {passwordVisible ? (
                                  <Icon.Eye className="react-feather-custom" />
                                ) : (
                                  <Icon.EyeOff className="react-feather-custom" />
                                )}
                              </span>
                            </div>
                            <br />
                            {passwordError && (
                              <div id="passwordInfo">{passwordError}</div>
                            )}
                          </div>
                        </div>
                        <button
                          className="btn btn-primary w-100 login-btn"
                          type="submit"
                        >
                          Login
                        </button>
                      </form>
                      {/* /Login Form */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <>
        {/* Loading Modal */}
        <div
          className="modal fade"
          id="loadingModal"
          tabIndex={-1}
          aria-labelledby="loadingModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <div className="d-flex justify-content-center">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Loading Modal */}
      </>
    </>
  );
};

export default AdminSignin;
