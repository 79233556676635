import React from 'react';
import ImageWithBasePath from '../../../core/img/ImageWithBasePath';
import ReactApexChart from 'react-apexcharts';
import WorldMap from 'react-svg-worldmap';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { all_routes } from '../../../core/data/routes/all_routes';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { constants } from 'fs';
import styles from '../../../style/admin/css/admin.module.css';

const Dashboard = () => {
  const routes = all_routes;

  const access_token = sessionStorage.getItem('access_token');

  const [users, setUsers] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [services, setServices] = useState([]);
  const [bookings, setBookings] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const endpoints = ['users', 'driver', 'driver_service', 'booking'];
        const stateSetters = [setUsers, setDrivers, setServices, setBookings];

        for (let i = 0; i < endpoints.length; i++) {
          const url = `https://wgjgzlvwmoavtpeylund.supabase.co/rest/v1/${endpoints[i]}?select=*`;

          const response = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${access_token}`,
              apikey: process.env.REACT_APP_BEARER_TOKEN,
            },
          });

          stateSetters[i](response.data);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, []);

  const renderAcceptedStatus = (res: any) => {
    return (
      <div>
        <h6
          className={`${
            res.accepted === true
              ? 'badge-active'
              : res.acceoted === false
              ? 'badge-pending'
              : ''
          }`}
        >
          {res.accepted ? 'Accepted' : 'Pending'}
        </h6>
      </div>
    );
  };

  // Create a mapping from month numbers to their string representations
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  // Calculate the monthly revenues
  const monthlyRevenues = {};
  for (const booking of bookings) {
    const date = new Date(booking.created_at);
    const month = date.getMonth(); // getMonth() returns a zero-based month
    const year = date.getFullYear();
    const key = `${month}-${year}`;
    if (!monthlyRevenues[key]) {
      monthlyRevenues[key] = booking.price;
    } else {
      monthlyRevenues[key] += booking.price;
    }
  }

  // Convert the monthly revenues object to an array and create an array of month names
  const revenuesArray = [];
  const monthCategories = [];
  for (const key in monthlyRevenues) {
    const [month, year] = key.split('-');
    revenuesArray.push(monthlyRevenues[key]);
    monthCategories.push(monthNames[month] + ' ' + year);
  }

  const Revenue = {
    series: [
      {
        name: 'Revenue',
        data: revenuesArray,
        colors: [' #28cea2'],
      },
    ],
    chart: {
      height: 350,
      type: 'area',
    },
    fill: {
      colors: [' #28cea2'],
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: 'horizontal',
        shadeIntensity: 0.1,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 0.5,
        opacityTo: 0.5,
        stops: [0, 50, 100],
        colorStops: [],
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 1,
      curve: 'smooth',
      dashArray: [0, 8, 5],
      opacityFrom: 0.5,
      opacityTo: 0.5,
      colors: [' #28cea2'],
    },
    xaxis: {
      type: 'month',
      title: {
        text: 'Month',
      },
      categories: monthCategories,
    },
    yaxis: {
      title: {
        text: 'Revenue',
      },
    },
    tooltip: {},
  };

  // Calculate the number of bookings for each month
  const monthlyBookingsCount = {};
  for (const booking of bookings) {
    const date = new Date(booking.created_at);
    const month = date.getMonth(); // getMonth() returns a zero-based month
    const year = date.getFullYear();
    const key = `${month}-${year}`;
    if (!monthlyBookingsCount[key]) {
      monthlyBookingsCount[key] = 1;
    } else {
      monthlyBookingsCount[key]++;
    }
  }

  // Convert the monthly bookings object to an array and create an array of month names
  const bookingsCountArray = [];
  const bookingsMonthCategories = [];
  for (const key in monthlyBookingsCount) {
    const [month, year] = key.split('-');
    bookingsCountArray.push(monthlyBookingsCount[key]);
    bookingsMonthCategories.push(monthNames[month] + ' ' + year);
  }

  const chartData = {
    colors: ['#28cea2'],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      title: {
        text: 'Month',
      },
      categories: monthCategories,
      
    },
    yaxis: {
      title: {
        text: 'Booking',
      },
      labels: {
        formatter: function (val) {
          return Math.round(val);
        },
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      // position: '',
      width: 400,
      // position: 'top',
    },
    series: [
      {
        name: 'Bookings',
        type: 'column',
        data: bookingsCountArray,
      },
    ],
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: false,
      },
    },
  };

  const dataCards = [
    {
      icon: 'assets/admin/img/icons/user.svg',
      title: 'Users',
      count: users.length,
    },
    {
      icon: 'assets/admin/img/icons/user-circle.svg',
      title: 'Drivers',
      count: drivers.length,
      class: 'home-provider',
    },
    {
      icon: 'assets/admin/img/icons/service.svg',
      title: 'Services',
      count: services.length,
      class: 'home-service',
    },
    {
      icon: 'assets/admin/img/icons/money.svg',
      title: 'Bookings',
      count: bookings.length,
      class: 'home-subscription',
    },
  ];

  return (
    <div className="page-wrapper" style={{ paddingTop: '100px' }}>
      <div className="content">
        <div className="row">
          {dataCards.map((item, index) => (
            <div
              className="col-lg-3 col-sm-6 col-12 d-flex widget-path widget-service"
              key={index}
            >
              <div className="card">
                <div className="card-body">
                  <div className={`home-user ${item.class}`}>
                    <div className="home-usercount d-flex justify-content-center">
                      <span>
                        <ImageWithBasePath src={item.icon} alt="img" />
                      </span>
                      <h4 style={{ paddingTop: '10px', marginRight: '20px' }}>
                        {item.title}
                      </h4>
                    </div>
                    <div className="home-usercontent d-flex justify-content-center">
                      <div className="home-usercontents">
                        <div className="home-usercontentcount">
                          <h2 className="counters">{item.count}</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="row">
          <div className="col-lg-6 col-sm-6 col-12 d-flex  widget-path">
            <div className="card">
              <div className="card-body">
                <div className="home-user">
                  <div className="home-head-user">
                    <h2>Revenue Summary</h2>
                  </div>
                  <div className="chartgraph">
                    <ReactApexChart
                      options={Revenue}
                      series={Revenue.series}
                      type="area"
                      height={350}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-sm-6 col-12 d-flex  widget-path">
            <div className="card">
              <div className="card-body">
                <div className="home-user">
                  <div className="home-head-user">
                    <h2>Booking Summary</h2>
                  </div>
                  <div className="chartgraph">
                    <ReactApexChart
                      options={chartData}
                      series={chartData.series}
                      type="bar"
                      height={350}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 widget-path">
            <div className="card mb-0">
              <div className="card-body">
                <div className="home-user">
                  <div className="home-head-user home-graph-headerr">
                    <h2>Recent Bookings</h2>
                    <Link to={routes.booking} className="btn btn-viewalll">
                      View All
                      <ImageWithBasePath
                        src="assets/admin/img/icons/arrow-right.svg"
                        className="ms-2"
                        alt="img"
                      />
                    </Link>
                  </div>
                  <div className="table-responsive datatable-nofooter">
                    <table className="table datatable">
                      <DataTable
                        paginatorTemplate=" CurrentPageReport"
                        currentPageReportTemplate="{first} to {last} of {totalRecords}"
                        value={bookings}
                        sortField="created_at"
                        sortOrder={-1}
                        rows={5}
                        tableStyle={{ minWidth: '50rem' }}
                      >
                        <Column sortable field="id" header="ID"></Column>
                        <Column
                          sortable
                          field="created_at"
                          header="Created At"
                        ></Column>
                        <Column
                          field="driver_service_id"
                          header="Driver Service ID"
                        ></Column>
                        <Column field="driver_id" header="Driver ID"></Column>
                        <Column sortable field="date" header="Date"></Column>
                        <Column field="time" header="Time"></Column>
                        <Column
                          field="customer_name"
                          header="Customer Name"
                        ></Column>
                        <Column
                          field="customer_email"
                          header="Customer Email"
                        ></Column>
                        <Column
                          field="customer_phone"
                          header="Customer Phone"
                        ></Column>
                        <Column
                          field="booking_ref"
                          header="Booking Ref"
                        ></Column>
                        <Column
                          field="collection_location"
                          header="Collection Location"
                        ></Column>
                        <Column
                          field="dropoff_location"
                          header="Dropoff Location"
                        ></Column>
                        <Column sortable field="price" header="Price"></Column>
                        <Column
                          field="description"
                          header="Description"
                        ></Column>
                        <Column field="van_size" header="Van Size"></Column>
                        <Column
                          field="helper_count"
                          header="Helper Count"
                        ></Column>
                        <Column field="packings" header="Packings"></Column>
                        <Column field="assembly" header="Assembly"></Column>
                        <Column field="piano" header="Piano"></Column>
                        <Column field="floors" header="Floors"></Column>
                        <Column field="hours" header="Hours"></Column>
                        <Column
                          header="Status"
                          body={renderAcceptedStatus}
                        ></Column>
                      </DataTable>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
