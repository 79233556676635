import { DataTable } from 'primereact/datatable';
import React, { useEffect, useState } from 'react';
import { Column } from 'primereact/column';
import * as Icon from 'react-feather';
import { Link, useParams } from 'react-router-dom';
import { all_routes } from '../../../core/data/routes/all_routes';
import axios from 'axios';
import styles from '../../../style/admin/css/admin.module.css';

const DocumentsHistory = () => {
  const access_token = sessionStorage.getItem('access_token');
  const routes = all_routes;

  const [documents, setDocuments] = useState([]);
  const { serviceId } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const serviceResponse = await axios.get(
          `https://wgjgzlvwmoavtpeylund.supabase.co/rest/v1/driver_service?id=eq.${serviceId}`,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
              apikey: process.env.REACT_APP_BEARER_TOKEN,
            },
          },
        );

        const documentTypes = [
          'external',
          'loading',
          'license_front',
          'license_back',
          'profile',
          'motor_insurance',
          'goods_in_transit',
          'public_liability',
        ];

        const promises = serviceResponse.data.flatMap((service) => {
          const driverId = service.driver_id;
          const driverServiceId = service.id;
          return documentTypes.map(
            (documentType) =>
              axios
                .get(
                  `https://wgjgzlvwmoavtpeylund.supabase.co/storage/v1/object/authenticated/documents/${driverId}/${driverServiceId}/${documentType}/0`,
                  {
                    headers: {
                      Authorization: `Bearer ${access_token}`,
                      apikey: process.env.REACT_APP_BEARER_TOKEN,
                    },
                    responseType: 'blob',
                  },
                )
                .catch(() => null), // Return null if the request fails
          );
        });

        const responses = await Promise.all(promises);
        const data = await Promise.all(
          responses
            .filter((response) => response !== null) // Filter out null responses
            .map((response, index) => {
              return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () =>
                  resolve({
                    type: documentTypes[index % documentTypes.length],
                    name: response.config.url.split('/').pop(),
                    url: reader.result,
                  });
                reader.onerror = reject;
                reader.readAsDataURL(response.data);
              });
            }),
        );

        setDocuments(data);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, [serviceId]);

  const documentNames = {
    external: 'External Document',
    loading: 'Loading Document',
    license_front: 'License Front',
    license_back: 'License Back',
    profile: 'Profile Document',
    motor_insurance: 'Motor Insurance',
    goods_in_transit: 'Goods in Transit',
    public_liability: 'Public Liability',
  };
  const renderDocumentColumn = (rowData) => {
    return (
      <a href={rowData.url} download={rowData.name}>
        {rowData.type}
        <Icon.Download
          className="react-feather-icon"
          style={{ marginLeft: '10px' }}
        />
      </a>
    );
  };

  const renderNamesColumn = (rowData) => {
    return <div>{documentNames[rowData.type]}</div>;
  };

  return (
    <>
      <div className="page-wrapper page-settings">
        <div className="content">
          <div
            className="content-page-headerr content-page-headersplit"
            style={{ paddingTop: '100px' }}
          >
            <h5>Documents</h5>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="tab-sets">
                <div className="tab-contents-sets">
                  <ul>
                    <li>
                      <Link to={routes.documents}>
                        <Icon.ArrowLeft
                          className="react-feather-icon"
                          style={{ marginLeft: '10px' }}
                        />{' '}
                        Back to Documents Dashboard{' '}
                      </Link>{' '}
                    </li>
                  </ul>
                </div>
                <div className="tab-contents-count">
                  <h6>Showing {documents.length} results</h6>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 ">
              <div className="table-resposnive table-div">
                <table className="table datatable">
                  <DataTable
                    paginatorTemplate="RowsPerPageDropdown CurrentPageReport PrevPageLink PageLinks NextPageLink  "
                    currentPageReportTemplate="{first} to {last} of {totalRecords}"
                    value={documents}
                    paginator
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    tableStyle={{ minWidth: '50rem' }}
                  >
                    <Column
                      sortable
                      field="name"
                      header="Document Name"
                      body={renderNamesColumn}
                    ></Column>
                    <Column
                      sortable
                      field="url"
                      header="Document"
                      body={renderDocumentColumn}
                    ></Column>
                  </DataTable>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DocumentsHistory;
