/* eslint-disable @typescript-eslint/no-unused-vars */
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { all_routes } from '../../../core/data/routes/all_routes';
import axios from 'axios';
import styles from '../../../style/admin/css/admin.module.css';

const AvailableJobs = () => {
  const routes = all_routes;
  const access_token = sessionStorage.getItem('access_token');
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          'https://wgjgzlvwmoavtpeylund.supabase.co/rest/v1/available_jobs?select=*',
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
              apikey: process.env.REACT_APP_BEARER_TOKEN,
            },
          },
        );

        setJobs(response.data);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <div className="page-wrapper page-settings">
        <div className="content">
          <div
            className="content-page-headerr content-page-headersplit"
            style={{ paddingTop: '100px' }}
          >
            <h5>Available Jobs List</h5>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="tab-sets">
                <div className="tab-contents-sets">
                  <ul>
                    <li>
                      <Link to={routes.availableJobs} className="active">
                        All Available Jobs
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="tab-contents-count">
                  <h6>Showing {jobs.length} results</h6>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 ">
              <div className="table-resposnive table-div">
                <table className="table datatable">
                  <DataTable
                    paginatorTemplate="RowsPerPageDropdown CurrentPageReport PrevPageLink PageLinks NextPageLink  "
                    currentPageReportTemplate="{first} to {last} of {totalRecords}"
                    value={jobs}
                    paginator
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    tableStyle={{ minWidth: '50rem' }}
                  >
                    <Column sortable field="id" header="ID"></Column>
                    <Column
                      sortable
                      field="created_at"
                      header="Created At"
                    ></Column>
                    <Column sortable field="date" header="Date"></Column>
                    <Column field="time" header="Time"></Column>
                    <Column
                      field="customer_name"
                      header="Customer Name"
                    ></Column>
                    <Column
                      field="customer_email"
                      header="Customer Email"
                    ></Column>
                    <Column
                      field="customer_phone"
                      header="Customer Phone"
                    ></Column>
                    <Column field="booking_ref" header="Booking Ref"></Column>
                    <Column
                      field="collection_location"
                      header="Collection Location"
                    ></Column>
                    <Column
                      field="dropoff_location"
                      header="Dropoff Location"
                    ></Column>
                    <Column sortable field="price" header="Price"></Column>
                    <Column field="description" header="Description"></Column>
                    <Column field="van_size" header="Van Size"></Column>
                    <Column field="helper_count" header="Helper Count"></Column>
                    <Column field="packings" header="Packings"></Column>
                    <Column field="assembly" header="Assembly"></Column>
                    <Column field="piano" header="Piano"></Column>
                    <Column field="floors" header="Floors"></Column>
                    <Column field="hours" header="Hours"></Column>
                  </DataTable>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AvailableJobs;
