import React, { useEffect, useState } from 'react';
import { Column } from 'primereact/column';
import { Link } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import * as Icon from 'react-feather';
import { all_routes } from '../../../core/data/routes/all_routes';
import axios from 'axios';
import styles from '../../../style/admin/css/admin.module.css';

const VerifiedServices = () => {
  const routes = all_routes;
  const access_token = sessionStorage.getItem('access_token');
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [verifiedStatus, setVerifiedStatus] = useState<boolean | null>(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          'https://wgjgzlvwmoavtpeylund.supabase.co/rest/v1/driver_service?select=*',
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
              apikey: process.env.REACT_APP_BEARER_TOKEN,
            },
          },
        );

        // Filter the services to only include verified services
        const verifiedServices = response.data.filter(
          (service: any) => service.verified === true,
        );

        setServices(verifiedServices);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, []);

  const handleSave = async (event: React.FormEvent) => {
    event.preventDefault();

    if (selectedService) {
      const url = `https://wgjgzlvwmoavtpeylund.supabase.co/rest/v1/driver_service?id=eq.${selectedService.id}`;
      const body = { verified: verifiedStatus };
      const headers = {
        Authorization: `Bearer ${access_token}`, // Replace with the actual access token
        apiKey: process.env.REACT_APP_BEARER_TOKEN,
        Prefer: 'return=representation',
      };

      try {
        const response = await axios.patch(url, body, { headers });

        // Update the services state to reflect the change
        setServices(
          services.map((service) =>
            service.id === selectedService.id
              ? { ...service, verified: verifiedStatus }
              : service,
          ),
        );
        window.location.reload();
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  const handleServiceDelete = async () => {
    if (selectedService) {
      const url = `https://wgjgzlvwmoavtpeylund.supabase.co/rest/v1/driver_service?id=eq.${selectedService.id}`;
      const headers = {
        Authorization: `Bearer ${access_token}`, // Replace with the actual access token
        apiKey: process.env.REACT_APP_BEARER_TOKEN,
      };

      try {
        const response = await axios.delete(url, { headers });

        setServices(
          services.filter((service) => service.id !== selectedService.id),
        );
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  const renderVerifiedStatus = () => {
    return <h6 className="badge-active">Verified</h6>;
  };
  const renderBody3 = (rowData: any) => {
    return (
      <div className="table-actions render-action d-flex">
        <Link
          className="btn delete-table me-2"
          to="#"
          data-bs-toggle="modal"
          data-bs-target="#edit-item"
          onClick={() => setSelectedService(rowData)}
        >
          <Icon.Edit className="react-feather-custom" />
        </Link>
        <Link
          className="btn delete-table"
          to="#"
          data-bs-toggle="modal"
          data-bs-target="#delete-item"
          onClick={() => setSelectedService(rowData)}
        >
          <Icon.Trash2 className="react-feather-custom " />
        </Link>
      </div>
    );
  };
  return (
    <>
      <div className="page-wrapper page-settings">
        <div className="content">
          <div
            className="content-page-headerr content-page-headersplit"
            style={{ paddingTop: '100px' }}
          >
            <h5>Verified Services</h5>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="tab-sets">
                <div className="tab-contents-sets">
                  <ul>
                    <li>
                      <Link to={routes.allServices}>All Services</Link>
                    </li>
                    <li>
                      <Link to={routes.verifiedServices} className="active">
                        Verified
                      </Link>
                    </li>
                    <li>
                      <Link to={routes.unverifiedServices}>Unverified </Link>
                    </li>
                  </ul>
                </div>
                <div className="tab-contents-count">
                  <h6>Showing {services.length} results</h6>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 ">
              <div className="table-resposnive table-div">
                <table className="table datatable">
                  <DataTable
                    paginatorTemplate="RowsPerPageDropdown CurrentPageReport PrevPageLink PageLinks NextPageLink  "
                    currentPageReportTemplate="{first} to {last} of {totalRecords}"
                    value={services}
                    paginator
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    tableStyle={{ minWidth: '50rem' }}
                  >
                    <Column sortable field="id" header="ID"></Column>
                    <Column field="driver_id" header="Driver ID"></Column>
                    <Column
                      sortable
                      field="created_at"
                      header="Created At"
                    ></Column>
                    <Column field="first_name" header="First Name"></Column>
                    <Column field="last_name" header="Last Name"></Column>
                    <Column
                      field="mobile_number"
                      header="Mobile Number"
                    ></Column>
                    <Column field="email_address" header="Email"></Column>
                    <Column field="van_size" header="Van Size"></Column>
                    <Column
                      field="location_base"
                      header="Location Base"
                    ></Column>
                    <Column
                      sortable
                      field="location_radius"
                      header="Location Radius"
                    ></Column>
                    <Column field="vehicle_reg" header="Vehicle Reg"></Column>
                    <Column field="active" header="Active"></Column>
                    <Column
                      header="Verified Status"
                      body={renderVerifiedStatus}
                    ></Column>

                    <Column header="Action" body={renderBody3}></Column>
                  </DataTable>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <>
        {/* Delete User */}
        <div className="modal fade" id="delete-item">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-headerr">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body pt-0">
                <div className="text-center">
                  <Icon.Trash2
                    size={45}
                    color="#f05050"
                    className="text-danger fs-1"
                  />
                  <div className="mt-4">
                    <h4>Delete User?</h4>
                    <p className="text-muted mb-0">
                      Are you sure want to delete this user?
                    </p>
                  </div>
                </div>
                <div className="d-flex gap-2 justify-content-center mt-4">
                  <button
                    type="button"
                    className="btn w-sm btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn w-sm btn-danger"
                    onClick={handleServiceDelete}
                    data-bs-dismiss="modal"
                  >
                    Yes, Delete It!
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Delete User */}
        {/* Edit User */}
        <div
          className="modal fade"
          id="edit-item"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered ">
            <div className="modal-content">
              <div className="modal-headerr">
                <h5 className="modal-title">
                  Change the verified status of this service
                </h5>
                <button
                  type="button"
                  className="btn-close close-modal"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <Icon.X className="react-feather-custom me-2" />
                </button>
              </div>
              <form onSubmit={handleSave}>
                <div className="modal-body py-0">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Verified Status</label>
                        <Dropdown
                          value={verifiedStatus}
                          onChange={(e) => setVerifiedStatus(e.value)}
                          options={[
                            { name: 'Verified', value: true },
                            { name: 'Not Verified', value: false },
                          ]}
                          optionLabel="name"
                          placeholder="Select Verified Status"
                          className="select w-100"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer pt-0">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* /Edit User */}
      </>
    </>
  );
};

export default VerifiedServices;
