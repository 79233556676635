import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import CompletedBooking from './bookings/completed-booking';
import Booking from './bookings/booking';
import Dashboard from './dashboard/dashboard';
import UnverifiedServices from './services/unverified-services';
import VerifiedServices from './services/verified-services';
import AllService from './services/all-service';
import AdminSignin from './authentication/signin';
import PendingBooking from './bookings/pending-booking';
import AvailableJobs from './available-jobs/available-jobs';
import Documents from './documents/documents';
import DocumentsHistory from './documents/documents-history';
import SessionExpiredAdmin from './session-expired/session-expired-admin';
import Users from './users/users';
import Providers from './users/providers';

const AdminRoutes = () => {
  const all_admin_routes = [
    {
      path: '/booking/completed-booking',
      name: 'completed-booking',
      element: <CompletedBooking />,
      route: Route,
    },
    {
      path: 'booking/pending-booking',
      name: 'pending-booking',
      element: <PendingBooking />,
      route: Route,
    },
    {
      path: '/booking',
      name: 'Booking',
      element: <Booking />,
      route: Route,
    },
    {
      path: '/available-jobs',
      name: 'available-jobs',
      element: <AvailableJobs />,
      route: Route,
    },

    {
      path: '/users',
      name: 'users',
      element: <Users />,
      route: Route,
    },
    {
      path: '/users/providers',
      name: 'providers',
      element: <Providers />,
      route: Route,
    },
    {
      path: '/session-expired-admin',
      name: 'SessionExpired',
      element: <SessionExpiredAdmin />,
      route: Route,
    },
    {
      path: '/documents',
      name: 'documents',
      element: <Documents />,
      route: Route,
    },

    {
      path: '/documents/documents-history/:serviceId',
      name: 'documents-history',
      element: <DocumentsHistory />,
      route: Route,
    },

    {
      path: '/services/unverified-services',
      name: 'unverified-services',
      element: <UnverifiedServices />,
      route: Route,
    },

    {
      path: '*',
      name: 'NotFound',
      element: <Navigate to="/" />,
      route: Route,
    },

    {
      path: '/services/all-services',
      name: 'all-services',
      element: <AllService />,
      route: Route,
    },
    {
      path: '/services/verified-services',
      name: 'verified-services',
      element: <VerifiedServices />,
      route: Route,
    },

    {
      path: '/signin',
      name: 'signin',
      element: <AdminSignin />,
      route: Route,
    },

    {
      path: '/dashboard',
      name: 'dashboard',
      element: <Dashboard />,
      route: Route,
    },

  ];


  return (
    <>
      <Routes>
        <Route>
          {all_admin_routes.map((route, idx) => (
            <Route path={route.path} element={route.element} key={idx} />
          ))}
          {/* <Route path="/device-management" element={DeviceManagement} /> */}
        </Route>
      </Routes>
    </>
  );
};

export default AdminRoutes;
