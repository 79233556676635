import React, { useEffect, useState } from 'react';
import AdminRoutes from './admin.routes';
import AdminHeader from './common/header';
import AdminSidebar from './common/sidebar';
import { useLocation, useNavigate } from 'react-router-dom';
import PageLoader from '../../core/loader';
import { all_routes } from '../../core/data/routes/all_routes';
import axios from 'axios';

const Admin = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const routes = all_routes;
  const [isLoading, setIsLoading] = useState(false);
  const access_token = sessionStorage.getItem('access_token');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          'https://wgjgzlvwmoavtpeylund.supabase.co/rest/v1/admin?select=*',
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
              apiKey: process.env.REACT_APP_BEARER_TOKEN,
            },
          },
        );

        // Check if driver exists
        if (response.data.length > 0) {
          // Driver exists, check if user is logged in
          const isLoggedIn = sessionStorage.getItem('isLoggedIn') === 'true';
          if (!isLoggedIn) {
            navigate(routes.sessionExpiredAdmin);
          }
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    setIsLoading(true);
  }, [location.pathname]);

  useEffect(() => {
    const delay = 2000;
    setTimeout(() => {
      setIsLoading(false);
    }, delay);
  }, [location.pathname]);

  useEffect(() => {
    // Skip the token validity check for the login and signup routes
    if (location.pathname === routes.signin) {
      return;
    }

    const checkTokenValidity = () => {
      const expiresAt = Number(sessionStorage.getItem('expires_at')); // Convert to number
      const access_token = sessionStorage.getItem('access_token');

      if (!access_token || expiresAt) {
        const currentTime = Math.floor(Date.now() / 1000); // Convert to Unix timestamp
        if (!access_token || currentTime >= expiresAt) {
          // The token has expired or does not exist, update isLoggedIn
          sessionStorage.setItem('isLoggedIn', 'false');

          // Redirect the user to the session expired page
          navigate(routes.sessionExpiredAdmin);
        }
      }
    };

    // Check token validity immediately and then every minute
    checkTokenValidity();
    const intervalId = setInterval(checkTokenValidity, 60 * 1000);

    // Clean up the interval on unmount
    return () => clearInterval(intervalId);
  }, [location.pathname]); // Add location.pathname to the dependency array
  return (
    <>
      {isLoading && <PageLoader />}
      {!isLoading && (
        <>
          {location.pathname == '/admin/signin' ||
          location?.pathname === '/admin/session-expired-admin'? (
            <></>
          ) : (
            <>
              <AdminHeader />
              <AdminSidebar />
            </>
          )}
          <AdminRoutes />
        </>
      )}
    </>
  );
};

export default Admin;
