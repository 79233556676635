import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import { all_routes } from '../../core/data/routes/all_routes';
import HomeOne from '../frontend/home/home-one/home-one';
import Pages from '../frontend/pages/pages';
import Services from '../frontend/services/services';
import Providers from '../frontend/providers/providers';
import Admin from '../admin/admin';
import Booking1 from '../frontend/pages/booking/booking-1';
import BookingDone from '../frontend/pages/booking/booking-done';
import BookingPayment from '../frontend/pages/booking/booking-payment';
import ChooseSignup from '../frontend/pages/authentication/choose-signup';
import Error404 from '../frontend/pages/Error page/error404';
import EmailOtp from '../frontend/pages/authentication/email-otp';
import Login from '../frontend/pages/authentication/login';
import ResetPassword from '../frontend/pages/authentication/reset-password';
import PasswordRecovery from '../frontend/pages/authentication/password-recovery';
import Success from '../frontend/pages/authentication/success';
const routes = all_routes;

const publicRoutes = [
  {
    path: routes.homeOne,
    name: 'home-one',
    element: <HomeOne />,
    route: Route,
  },
  {
    path: '/',
    name: 'Root',
    element: <Navigate to="/" />,
    route: Route,
  },
  {
    path: '*',
    name: 'NotFound',
    element: <Navigate to="/" />,
    route: Route,
  },

  // pages module's path
  {
    path: routes.booking1,
    name: 'booking-1',
    element: <Booking1 />,
    route: Route,
  },

  {
    path: routes.bookingDone,
    name: 'booking-done',
    element: <BookingDone />,
    route: Route,
  },
  {
    path: routes.bookingPayment,
    name: 'booking-payment',
    element: <BookingPayment />,
    route: Route,
  },

  {
    path: routes.error404,
    name: 'error404',
    element: <Error404 />,
    route: Route,
  },
  {
    path: routes.error500,
    name: 'error404',
    element: <Error404 />,
    route: Route,
  },

  // provider module's path

  //customer module's path

  // blog module's path
  // service path

  {
    path: routes.pages,
    name: 'pages',
    element: <Pages />,
    route: Route,
  },
  {
    path: routes.services,
    name: 'services',
    element: <Services />,
    route: Route,
  },
  {
    path: routes.providers,
    name: 'providers',
    element: <Providers />,
    route: Route,
  },
  {
    path: '/authentication/reset-password',
    name: 'reset-password',
    element: <ResetPassword />,
    route: Route,
  },
  {
    path: '/authentication/password-recovery',
    name: 'password-recovery',
    element: <PasswordRecovery />,
    route: Route,
  },

  {
    path: '/authentication/login',
    name: 'login',
    element: <Login />,
    route: Route,
  },
  {
    path: '/authentication/email-otp',
    name: 'email-Otp',
    element: <EmailOtp />,
    route: Route,
  },
  {
    path: '/authentication/choose-signup',
    name: 'choose-signup',
    element: <ChooseSignup />,
    route: Route,
  },
  {
    path: '/authentication/success',
    name: 'success',
    element: <Success />,
    route: Route,
  },

  // Admin Module Path

  {
    path: routes.admin,
    name: 'admin',
    element: <Admin />,
    route: Route,
  },
  {
    path: 'admin',
    name: 'Root',
    element: <Navigate to="/admin/dashboard" />,
    route: Route,
  },
];

export { publicRoutes };
