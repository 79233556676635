import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars-2';
import * as Icon from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import {
  set_mouseoversidebar_data,
  set_toggleSidebar_data_2,
} from '../../../core/data/redux/action';
import { all_routes } from '../../../core/data/routes/all_routes';
import ImageWithBasePath from '../../../core/img/ImageWithBasePath';
import { AppState, SideBarData } from '../../../core/models/interface';
import styles from '../../../style/admin/css/admin.module.css';

const routes = all_routes;

const sidebar = [
  {
    tittle: 'Home',
    showAsTab: false,
    separateRoute: false,
    menu: [
      {
        menuValue: 'Dashboard',
        hasSubRoute: false,
        showSubRoute: false,
        route: routes.dashboard,
        icon: <Icon.Grid className="react-feather-icon" />,
      },
    ],
  },
  {
    tittle: 'Services',
    showAsTab: false,
    separateRoute: false,
    menu: [
      {
        menuValue: 'Services',
        hasSubRoute: false,
        showSubRoute: false,
        route: routes.allServices,
        icon: <Icon.Briefcase className="react-feather-icon" />,
      },
    ],
  },
  {
    tittle: 'Document Verification',
    showAsTab: false,
    separateRoute: false,
    menu: [
      {
        menuValue: 'Documents',
        hasSubRoute: false,
        showSubRoute: false,
        route: routes.documents,
        icon: <Icon.File className="react-feather-icon" />,
      },
    ],
  },
  {
    tittle: 'Booking',
    showAsTab: false,
    separateRoute: false,
    menu: [
      {
        menuValue: 'Bookings',
        hasSubRoute: false,
        showSubRoute: false,
        route: routes.booking,
        icon: <Icon.DollarSign className="react-feather-icon" />,
      },
      {
        menuValue: 'Available Jobs',
        hasSubRoute: false,
        showSubRoute: false,
        route: routes.availableJobs,
        icon: <Icon.Clipboard className="react-feather-icon" />,
      },
    ],
  },
  {
    tittle: 'User Management',
    showAsTab: false,
    separateRoute: false,
    menu: [
      {
        menuValue: 'Users',
        hasSubRoute: false,
        showSubRoute: false,
        route: routes.users,
        icon: <Icon.User className="react-feather-icon" />,
      },
      {
        menuValue: 'Drivers',
        hasSubRoute: false,
        showSubRoute: false,
        route: routes.userProviders,
        icon: <Icon.Users className="react-feather-icon" />,
      },
    ],
  },

  {
    tittle: 'Settings',
    showAsTab: false,
    separateRoute: false,
    menu: [
      {
        menuValue: 'Logout',
        hasSubRoute: false,
        showSubRoute: false,
        route: routes.signin,
        icon: <Icon.LogOut className="react-feather-icon" />,
        onClick: () => {
          sessionStorage.removeItem('access_token');
          sessionStorage.removeItem('user_id');
          sessionStorage.setItem('isLoggedIn', 'false');
        },
      },
    ],
  },
];

const AdminSidebar = () => {
  const toggle_data = useSelector((state: AppState) => state.mouseOverSidebar);
  const toggle_data2 = useSelector((state: AppState) => state.toggleSidebar2);
  const dispatch = useDispatch();

  const [sidebarData, setSidebarData] = useState(sidebar);

  useEffect(() => {
    dispatch(set_toggleSidebar_data_2(true));
  }, [dispatch]);

  const toggle = () => {
    dispatch(set_toggleSidebar_data_2(toggle_data2 ? false : true));
  };
  const toogle1 = () => {
    dispatch(set_mouseoversidebar_data(toggle_data ? false : true));
  };
  const Location = useLocation();
  // const activeRouterPath = (routesArray: string) => {
  //   return (routesArray = Location.pathname);
  // };

  // const activeRouterMenu = (menu: string) => {
  //   return Location.pathname.includes(menu.toLowerCase());
  // };
  const activeRouterMenu = (route: string) => {
    return Location.pathname.includes(route);
  };
  const activeRouterPath = (route: string) => {
    return Location.pathname === route;
  };

  const activeRouterPath2 = (route: string) => {
    return Location.pathname.includes(route);
  };
  const expandSubMenus = (menu: SideBarData) => {
    sessionStorage.setItem('menuValue', menu.menuValue);
    const updatedAdminSidebar = sidebarData.map((section) => {
      const updatedSection = { ...section };
      updatedSection.menu = section.menu.map((menuItem) =>
        menu.menuValue != menuItem.menuValue
          ? {
              ...menuItem,
              showSubRoute: false,
            }
          : {
              ...menuItem,
              showSubRoute: !menu.showSubRoute,
            },
      );
      return updatedSection;
    });
    setSidebarData(updatedAdminSidebar);
  };

  return (
    <div
      className="sidebarr"
      id="sidebar"
      onMouseEnter={toogle1}
      onMouseLeave={toogle1}
    >
      <div className="sidebarr-headerr">
        <div className="sidebarr-logo">
          <Link to="/admin/dashboard">
            <ImageWithBasePath
              src="assets/img/newLogo.png"
              className="img-fluid logo"
              alt="Logo"
            />
          </Link>
          <Link to={routes.dashboard}>
            <ImageWithBasePath
              src="assets/img/newLogo.png"
              className="img-fluid logo-small"
              alt="Logo"
            />
          </Link>
        </div>
        {/* <div className="siderbar-toggle">
          <Link to={'#'} onClick={toggle}>
            <label className="switch" id="toggle_btn">
              <input type="checkbox" />
              <span className="slider round" />
            </label>
          </Link>
        </div> */}
      </div>
      <div className="sidebarr-inner slimscroll">
        <Scrollbars>
          <div id="sidebarr-menu" className="sidebarr-menu">
            <ul>
              {sidebarData.map((mainTittle: SideBarData, index: number) => {
                return (
                  <>
                    <li className="menu-title" key={index + 1}>
                      <h6>{mainTittle.tittle}</h6>
                    </li>
                    {mainTittle.menu.map(
                      (menu: SideBarData, menuIndex: number) => {
                        return (
                          <>
                            {menu.hasSubRoute === false ? (
                              <li key={menuIndex + 1}>
                                <Link
                                  to={menu.route}
                                  className={`${
                                    activeRouterPath(menu.route) ? 'active' : ''
                                  }`}
                                  onClick={menu.onClick} 
                                >
                                  {menu.icon} <span>{menu.menuValue}</span>
                                </Link>
                              </li>
                            ) : (
                              <li
                                className={`submenu ${
                                  activeRouterPath2(menu.route) ? 'active' : ''
                                }`}
                              >
                                <Link
                                  to="#"
                                  onClick={() => expandSubMenus(menu)}
                                  className={`${
                                    menu.showSubRoute ? 'subdrop' : ''
                                  }`}
                                >
                                  {menu.icon}
                                  <span>{menu.menuValue}</span>
                                  <span className="menu-arrow">
                                    {menu.showSubRoute ? (
                                      <Icon.ChevronDown className="react-feather-custom"></Icon.ChevronDown>
                                    ) : (
                                      <Icon.ChevronRight className="react-feather-custom"></Icon.ChevronRight>
                                    )}
                                  </span>
                                </Link>

                                <ul
                                  style={{
                                    display: menu.showSubRoute
                                      ? 'block'
                                      : 'none',
                                  }}
                                >
                                  {menu.subMenus.map(
                                    (
                                      subMenus: SideBarData,
                                      subMenu: number,
                                    ) => {
                                      return (
                                        <>
                                          <li key={subMenu + 1}>
                                            <Link
                                              to={subMenus.route}
                                              className={`${
                                                activeRouterPath(subMenus.route)
                                                  ? 'active'
                                                  : ''
                                              }`}
                                            >
                                              {subMenus.menuValue}
                                            </Link>
                                          </li>
                                        </>
                                      );
                                    },
                                  )}
                                </ul>
                              </li>
                            )}
                          </>
                        );
                      },
                    )}
                  </>
                );
              })}
            </ul>
          </div>
        </Scrollbars>
      </div>
    </div>
  );
};

export default AdminSidebar;
