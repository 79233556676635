/* eslint-disable @typescript-eslint/no-explicit-any */
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useState } from 'react';
import { Column } from 'primereact/column';
import { Link } from 'react-router-dom';
import styles from '../../../style/admin/css/admin.module.css';
import { all_routes } from '../../../core/data/routes/all_routes';
import axios from 'axios';

const Documents = () => {
  const access_token = sessionStorage.getItem('access_token');
  const routes = all_routes;

  const [driverServices, setDriverServices] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const serviceResponse = await axios.get(
          'https://wgjgzlvwmoavtpeylund.supabase.co/rest/v1/driver_service?select=*',
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
              apikey: process.env.REACT_APP_BEARER_TOKEN,
            },
          },
        );

        const driverServiceData = serviceResponse.data.map((service) => ({
          driverId: service.driver_id,
          serviceId: service.id,
        }));

        setDriverServices(driverServiceData);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <div className="page-wrapper page-settings">
        <div className="content">
          <div
            className="content-page-headerr content-page-headersplit"
            style={{ paddingTop: '100px' }}
          >
            <h5>Documents</h5>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="tab-sets">
              <div className="tab-contents-sets">
                  <ul>
                    <li>
                      <Link to={routes.documents} className="active">
                        All Documents
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="tab-contents-count">
                  <h6>Showing {driverServices.length} results</h6>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 ">
              <div className="table-resposnive table-div">
                <table className="table datatable">
                  <DataTable
                    paginatorTemplate="RowsPerPageDropdown CurrentPageReport PrevPageLink PageLinks NextPageLink  "
                    currentPageReportTemplate="{first} to {last} of {totalRecords}"
                    value={driverServices}
                    paginator
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    tableStyle={{ minWidth: '50rem' }}
                  >
                    <Column
                      sortable
                      field="driverId"
                      header="Driver ID"
                    ></Column>
                    <Column
                      sortable
                      field="serviceId"
                      header="Service ID"
                    ></Column>
                    <Column
                      sortable
                      field="action"
                      header="Action"
                      body={(rowData) => (
                        <td>
                          <Link
                            to={`${routes.documentsHistory}/${rowData.serviceId}`}
                            className="btn btn-history"
                          >
                            Documents
                          </Link>
                        </td>
                      )}
                    ></Column>
                  </DataTable>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Documents;
