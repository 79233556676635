import React from 'react';
import { Link } from 'react-router-dom';
import * as Icon from 'react-feather';
import { all_routes } from '../../../core/data/routes/all_routes';
import ImageWithBasePath from '../../../core/img/ImageWithBasePath';
import styles from '../../../style/admin/css/admin.module.css';

const SessionExpiredAdmin = () => {
  const routes = all_routes;
  return (
    <>
      <>
        {/* Session Expired */}
        <div className="main-wrapper error-page">
          <div className="content">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="error-wrap">
                    <h2>Your Session has expired</h2>
                    <p>
                      Please login again. Do not worry, we kept all of you
                      filters and breakdowns in place.
                    </p>
                    <Link to={routes.signin} className="btn btn-primary">
                      <Icon.ArrowLeftCircle className="react-feather-custom" />
                      Back to Admin Login
                    </Link>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="error-wrap">
                    <div className="error-img">
                      <ImageWithBasePath
                        className="img-fluid"
                        src="assets/img/session.png"
                        alt="img"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Session Expired */}
      </>
    </>
  );
};

export default SessionExpiredAdmin;
