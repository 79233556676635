import React, { useEffect, useState } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Link } from 'react-router-dom';
import { all_routes } from '../../../core/data/routes/all_routes';
import axios from 'axios';
import styles from '../../../style/admin/css/admin.module.css';

const Providers = () => {
  const routes = all_routes;
  const access_token = sessionStorage.getItem('access_token');
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          'https://wgjgzlvwmoavtpeylund.supabase.co/rest/v1/driver?select=*',
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
              apikey: process.env.REACT_APP_BEARER_TOKEN,
            },
          },
        );

        setUsers(response.data);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <div className="page-wrapper page-settings">
        <div className="content">
          <div
            className="content-page-headerr content-page-headersplit"
            style={{ paddingTop: '100px' }}
          >
            <h5>Drivers List</h5>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="tab-sets">
                <div className="tab-contents-sets">
                  <ul>
                    <li>
                      <Link to={routes.users} className="active">
                        All Drivers
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="tab-contents-count">
                  <h6>Showing {users.length} results</h6>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 ">
              <div className="table-resposnive table-div">
                <table className="table datatable">
                  <DataTable
                    paginatorTemplate="RowsPerPageDropdown CurrentPageReport PrevPageLink PageLinks NextPageLink  "
                    currentPageReportTemplate="{first} to {last} of {totalRecords}"
                    value={users}
                    paginator
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    tableStyle={{ minWidth: '50rem' }}
                  >
                    <Column sortable field="id" header="ID"></Column>
                    <Column sortable field='created_at' header='Created At'></Column>
                    <Column field='first_name' header='First Name'></Column>
                    <Column field='last_name' header='Last Name'></Column>
                    <Column field='email_address' header='Email Address'></Column>
                    <Column field='mobile_number' header='Mobile Number'></Column>
                    <Column field='gender' header='Gender'></Column>
                    <Column field='date_of_birth' header='Date of Birth'></Column>
                    <Column field='address' header='Address'></Column>
                    <Column field='verified' header='Verified'></Column>
                    <Column field='form_complete' header='Form Complete'></Column>
                  </DataTable>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Providers;
