import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import * as Icon from 'react-feather';
import PlacesAutocomplete from 'react-places-autocomplete';

import { Dropdown } from 'primereact/dropdown';
import { TimePicker } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import DefaultEditor from 'react-simple-wysiwyg';
import { all_routes } from '../../../../core/data/routes/all_routes';
import ProviderAuthHeader from './common/header1';
import { Calendar } from 'primereact/calendar';
import PhoneInput from 'react-phone-input-2';
import axios from 'axios';
import { Modal } from 'bootstrap';
const routes = all_routes;

const ProviderVerification = () => {
  dayjs.extend(customParseFormat);
  const navigate = useNavigate();
  const email1 = sessionStorage.getItem('email');
  const access_token = sessionStorage.getItem('access_token');
  const user_id = sessionStorage.getItem('user_id');

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState(email1);
  // const [date, setDate] = useState<Nullable<Date>>(null);
  // const [selectedGender, setGender] = useState(null);
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (
      !firstName ||
      !lastName ||
      !email ||
      !phone ||
      // !selectedGender ||
      // !date ||
      !address
    ) {
      setErrorMessage('Please fill out all the required fields!');
      return;
    }

    const loadingModalElement = document.getElementById('loadingModal');
    const loadingModal = new Modal(loadingModalElement);
    loadingModal.show();

    try {
      const params = {
        id: user_id,
        email_address: email,
        first_name: firstName,
        last_name: lastName,
        mobile_number: phone,
        // gender: selectedGender ? selectedGender.toLowerCase() : null,
        // date_of_birth: dayjs(date).format('DD/MM/YYYY'),
        address: address,
        form_complete: true,
        verified: true,
      };

      const response = await axios.post(
        'https://wgjgzlvwmoavtpeylund.supabase.co/rest/v1/driver',
        params,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            apiKey: process.env.REACT_APP_BEARER_TOKEN,
            Prefer: 'return=minimal',
          },
        },
      );

      loadingModal.hide();

      const successModalElement = document.getElementById('successmodal');
      const successModal = new Modal(successModalElement);
      successModal.show();

      setTimeout(() => {
        // Manually hide the modal and remove the modal backdrop
        successModal.hide();
        const modalBackdrop = document.querySelector('.modal-backdrop');
        if (modalBackdrop) {
          modalBackdrop.remove();
        }
        navigate(routes.providerService);
      }, 2000);
    } catch (error) {
      loadingModal.hide();

      console.error('Error:', error);
    }
  };

  const handleOnChange = (value: string, country: string) => {
    setPhone(value);
  };

  const handleAddressSelect = (address) => {
    setAddress(address);
  };

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  // const gender = ['Male', 'Female'];

  const options = {
    types: ['address'],
    componentRestrictions: { country: 'uk' },
  };

  return (
    <>
      <ProviderAuthHeader />

      <>
        {/* Breadcrumb */}
        <div className="breadcrumb-bar">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-12">
                <h2 className="breadcrumb-title">Provider Verification</h2>
              </div>
            </div>
          </div>
        </div>
        {/* /Breadcrumb */}
      </>

      <div className="page-wrapper">
        <div className="content">
          <div className="container">
            <div className="row">
              <div className="col-md-12 mx-auto">
                {/* /Service List */}
                <div className="service-inform-fieldset">
                  {/* Service Information */}
                  <fieldset id="first-field">
                    <div className="card add-service">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="sub-title">
                            <h6>Provider Information</h6>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="col-form-label">
                              First Name <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter First Name"
                              value={firstName}
                              onChange={handleFirstNameChange}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="col-form-label">
                              Last Name <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Last Name"
                              value={lastName}
                              onChange={handleLastNameChange}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="col-form-label">
                              Email <span className="text-danger">*</span>
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              placeholder="Enter Email Address"
                              value={email || ''}
                              onChange={handleEmailChange}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="col-form-label">
                              Mobile Number{' '}
                              <span className="text-danger">*</span>
                            </label>
                            <PhoneInput
                              country={'gb'}
                              value={phone}
                              onChange={handleOnChange}
                            />
                          </div>
                        </div>
                        {/* <div className="col-md-6">
                          <div className="form-group">
                            <label className="col-form-label">
                              Gender <span className="text-danger">*</span>
                            </label>
                            <Dropdown
                              value={selectedGender}
                              onChange={(e) => setGender(e.value)}
                              options={gender}
                              placeholder="Select Gender"
                              className="select w-100"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="col-form-label">
                              Date of birth{' '}
                              <span className="text-danger">*</span>
                            </label>
                            <Calendar
                              value={date}
                              onChange={(e) => setDate(e.value)}
                              placeholder="DD/MM/YYYY"
                            />
                          </div>
                        </div> */}
                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="col-form-label">
                              Address <span className="text-danger">*</span>
                            </label>
                            <PlacesAutocomplete
                              value={address}
                              onChange={(value) => {
                                setAddress(value);
                              }}
                              onSelect={handleAddressSelect}
                              options={options}
                            >
                              {({
                                getInputProps,
                                suggestions,
                                getSuggestionItemProps,
                                loading,
                              }) => (
                                <div>
                                  <input
                                    {...getInputProps({
                                      placeholder: 'Address',
                                      className: 'form-control',
                                    })}
                                  />
                                  <div className="autocomplete-dropdown-container">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map((suggestion, index) => (
                                      <div
                                        key={index}
                                        {...getSuggestionItemProps(suggestion, {
                                          className: suggestion.active
                                            ? 'suggestion-item--active'
                                            : 'suggestion-item',
                                        })}
                                      >
                                        <span>{suggestion.description}</span>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              )}
                            </PlacesAutocomplete>
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                  {/* /Service Information */}

                  {/* Documents */}
                  {/* <fieldset style={{ display: 'block' }}>
                    <div className="card add-service">
                      <div className="sub-title">
                        <h6>
                          Proof of ID
                          <span className="text-danger"> *</span>
                          <br />
                          Can not be expired and must match the name of the
                          account holder (Driving license card, Passport,
                          National identity card, HM Forces identity card,
                          Student card, Employment identification card)
                        </h6>
                      </div>
                      <div className="file-upload">
                        <ImageWithBasePath
                          src="assets/img/icons/upload-icon.svg"
                          alt="image"
                        />
                        <h6>
                          Drag &amp; drop files or <span>Browse</span>
                        </h6>
                        <p>Supported Formats: JPEG, PNG, PDF</p>
                        <input
                          type="file"
                          accept="image/jpeg, image/png, application/pdf"
                          onChange={handleProofOfIdChange}
                          multiple
                        />{' '}
                      </div>
                      <div className="file-preview">
                        <ul className="gallery-selected-img">
                          {proofOfId.map((file, index) => (
                            <li key={index}>
                              <div className="img-preview">
                                <img
                                  src={URL.createObjectURL(file)}
                                  alt={`Proof of ID ${index + 1}`}
                                  style={{ width: '100px', height: '100px' }} // Set the width and height here
                                />
                              </div>
                              <label className="custom_check">
                                <Link
                                  to="#"
                                  className="remove-gallery"
                                  onClick={() => deleteProofOfId(index)}
                                >
                                  <Icon.Trash2
                                    style={{ width: '16px', height: '16px' }}
                                  />
                                </Link>
                              </label>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <div className="card add-service">
                      <div className="sub-title">
                        <h6>
                          Proof of Address
                          <span className="text-danger"> *</span>
                          <br />
                          Must be issued within the last 3 month, must match the
                          name of the account holder and must display full
                          address (Utility bill, Bank statement, Council tax
                          bill, Mortgage statement)
                        </h6>
                      </div>
                      <div className="file-upload">
                        <ImageWithBasePath
                          src="assets/img/icons/upload-icon.svg"
                          alt="image"
                        />
                        <h6>
                          Drag &amp; drop files or <span>Browse</span>
                        </h6>
                        <p>Supported Formats: JPEG, PNG, PDF</p>
                        <input
                          type="file"
                          accept="image/jpeg, image/png, application/pdf"
                          onChange={handleProofOfAddressChange}
                          multiple
                        />{' '}
                      </div>
                      <div className="file-preview">
                        <ul className="gallery-selected-img">
                          {proofOfAddress.map((file, index) => (
                            <li key={index}>
                              <div className="img-preview">
                                <img
                                  src={URL.createObjectURL(file)}
                                  alt={`Proof of Address ${index + 1}`}
                                  style={{ width: '100px', height: '100px' }} // Set the width and height here
                                />
                              </div>
                              <label className="custom_check">
                                <Link
                                  to="#"
                                  className="remove-gallery"
                                  onClick={() => deleteProofOfAddress(index)}
                                >
                                  <Icon.Trash2
                                    style={{ width: '16px', height: '16px' }}
                                  />
                                </Link>
                              </label>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div> */}

                  {errorMessage && (
                    <div
                      style={{
                        color: 'red',
                        textAlign: 'center',
                        // Add any other styles you want
                      }}
                    >
                      {errorMessage}
                    </div>
                  )}

                  <div className="field-bottom-btns">
                    <div className="field-btns">
                      <button
                        className="btn btn-primary done_btn"
                        onClick={handleSubmit}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                  {/* </fieldset>  */}
                  {/* /Docuemnts */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <>
        {/* Modal Succss */}
        <div
          className="modal fade add-service-modal"
          id="successmodal"
          tabIndex={-1}
          aria-labelledby="successmodal"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <span>
                  <i className="fa-regular fa-circle-check" />
                </span>
                <h3>Success</h3>
                <p>Thanks for providing your details</p>
                <div className="popup-btn">
                  <a href={routes.providerService} className="btn btn-primary">
                    Go to Dashboard <i className="fa-solid fa-arrow-right" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Modal Succss */}
      </>

      <>
        {/* Loading Modal */}
        <div
          className="modal fade"
          id="loadingModal"
          tabIndex={-1}
          aria-labelledby="loadingModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <div className="d-flex justify-content-center">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Loading Modal */}
      </>
    </>
  );
};

export default ProviderVerification;
