import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ImageWithBasePath from '../../../core/img/ImageWithBasePath';
import * as Icon from 'react-feather';
import { set_is_mobile_sidebar } from '../../../core/data/redux/action';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import styles from '../../../style/admin/css/admin.module.css';

const AdminHeader = () => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const access_token = sessionStorage.getItem('access_token');
  const [user, setUser] = useState([]);
  const toggleFullscreen = () => {
    if (!isFullscreen) {
      // Request fullscreen
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      }
    } else {
      // Exit fullscreen
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }

    // Toggle the state
    setIsFullscreen(!isFullscreen);
  };
  // const mobileSidebar = useSelector((state : any) => state.mobileSidebar)
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          'https://wgjgzlvwmoavtpeylund.supabase.co/rest/v1/admin?select=*',
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
              apikey: process.env.REACT_APP_BEARER_TOKEN,
            },
          },
        );

        setUser(response.data);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="headerr">
      <div className="headerr-left">
        <Link to="index" className="logo">
          <ImageWithBasePath
            src="assets/img/newLogo.png"
            alt="Logo"
            width={30}
            height={30}
          />
        </Link>
        <Link to="index" className=" logo-small">
          <ImageWithBasePath
            src="assets/img/logovnbig.jpeg"
            alt="Logo"
            width={30}
            height={30}
          />
        </Link>
      </div>
      <Link
        className="mobile_btn"
        id="mobile_btn"
        to="#"
        onClick={() => {
          dispatch(set_is_mobile_sidebar(true));
        }}
      >
        <i className="fas fa-align-left" />
      </Link>
      <div className="headerr-split">
        <div className="page-headers"></div>
        <ul className="nav user-menuu">
          {/* Notifications */}
          <li className="nav-itemm">
            <Link to="/home-one" target="_blank" className="viewsite">
              <Icon.Globe className="react-feather-custom me-2"></Icon.Globe>
              View Site
            </Link>
          </li>

          <li className="nav-itemm  has-arrow dropdown-heads ">
            <Link
              onClick={toggleFullscreen}
              to="#"
              className="win-maximize headerr-feather-icons"
            >
              <Icon.Maximize className="react-feather-custom "></Icon.Maximize>
            </Link>
          </li>
          {/* User Menu */}
          <li className="nav-itemm dropdown">
            <Link to="#" className="user-link  nav-link">
              <div className="user-infos">
                <div className="user-info">
                  <h6>
                    {' '}
                    {user[0]?.first_name} {user[0]?.last_name}
                  </h6>
                  <p>{user[0]?.email_address}</p>
                </div>
              </div>
            </Link>
          </li>
          {/* /User Menu */}
        </ul>
      </div>
    </div>
  );
};

export default AdminHeader;
