export const all_routes = {
  // home module path
  homeOne: '/',

  // services module path
  services: '/services/*',
  serviceList: '/services/service-list',
  serviceFilter: '/services/service-filter',

  // providers module path
  providers: '/providers/*',
  providerProfileSettings: '/providers/settings/provider-profile-settings',
  providerCreateService: '/providers/provider-create-service',
  providerEditService: '/providers/provider-edit-service',
  providerBooking: '/providers/provider-booking',
  providerAvailableJobs: '/providers/provider-available-jobs',
  providerSignup: '/providers/authentication/provider-signup',
  providerRules: '/providers/authentication/provider-rules',
  providerVerification: '/providers/authentication/provider-verification',
  providerService: '/providers/provider-service',
  ProviderSecuritySettings: '/providers/settings/provider-security-settings',

  // pages module path
  pages: '/pages/*',
  error404: '/error/error-404',
  error500: '/error/error-500',
  faq: '/pages/faq',
  termsCondition: '/pages/terms-condition',
  booking1: '/pages/booking/booking-1',
  bookingPayment: '/pages/booking/booking-payment',
  bookingDone: '/pages/booking/booking-done',
  contactUs: '/pages/contact-us',
  chooseSignUp: '/authentication/choose-signup',
  emailOtp: '/authentication/email-otp',
  login: '/authentication/login',
  passwordRecovery: '/authentication/password-recovery',
  privacyPolicy: '/pages/privacy-policy',
  sessionExpired: '/pages/session-expired',
  success: '/authentication/success',
  resetPassword: '/authentication/reset-password',

  test: '',

  // Admin Module Path

  admin: '/admin/*',
  signin: '/admin/signin',
  dashboard: '/admin/dashboard',
  documents: '/admin/documents',
  documentsHistory: '/admin/documents/documents-history',
  booking: '/admin/booking',
  completedBooking: '/admin/booking/completed-booking',
  pendingBooking: '/admin/booking/pending-booking',
  availableJobs: '/admin/available-jobs',
  allServices: '/admin/services/all-services',
  unverifiedServices: '/admin/services/unverified-services',
  verifiedServices: '/admin/services/verified-services',
  users: '/admin/users',
  userProviders: '/admin/users/providers',
  sessionExpiredAdmin: '/admin/session-expired-admin',
};
